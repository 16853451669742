import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { Container, Grid, Button, Card, CardContent, CardActions } from "@mui/material";
import { Work } from "@mui/icons-material";
import "./Careers.scss";
import ScrollAnimation from "Views/ScrollAnimation/ScrollAnimation";
import JobApplicationForm from "Views/Jobs/JobApplicationForm";

function getDatePosted(postedDate) {
  const currentDate = new Date();
  const timeDiff = Math.abs(currentDate - new Date(postedDate));
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (daysDiff === 0) return "Today";
  else if (daysDiff === 1) return "Yesterday";
  else return `${daysDiff} days ago`;
}

function Careers() {
  const [data, setData] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchVacancies = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "vacancies"));
        const vacancies = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(vacancies);
      } catch (error) {
        console.error("Error fetching vacancies: ", error);
      }
    };

    fetchVacancies();
  }, []);

  const renderData = data.map(({ id, jobTitle, department, requirements, postDate, description }) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
      <ScrollAnimation animation="fade-in">
        <Card className="career-card">
          <CardContent>
            <h3>{jobTitle}</h3>
            <p className="department">{department}</p>
            <p className="date-posted">{getDatePosted(postDate)}</p>
          </CardContent>
          <CardActions>
            <Button
              className="apply-btn"
              component={Link}
              to="/Jobs"
              variant="contained"
              fullWidth
              startIcon={<Work />}
              state={{
                id: id,
                jobTitle: jobTitle,
                department: department,
                jobText: requirements,
                Description: description,
                PostDate: getDatePosted(postDate),
              }}
            >
              Apply for {jobTitle}
            </Button>
          </CardActions>
        </Card>
      </ScrollAnimation>
    </Grid>
  ));

  return (
    <div className="careers-page">
      <header className="careers-hero">
        <Container>
          <ScrollAnimation animation="fade-in">
            <h1>Your Opportunity to Join DevHouse</h1>
            <p>Discover the path to landing your dream job at DevHouse and becoming part of a team that creates innovative solutions.</p>
          </ScrollAnimation>
        </Container>
      </header>
      <main className="careers-main">
        <Container>
          <Grid container spacing={3}>
            {data.length > 0 ? renderData : (
              <Grid item xs={12}>
                <ScrollAnimation animation="fade-in">
                  <Card className="no-vacancies-card">
                    <CardContent>
                      <h2>No available careers now.</h2>
                      <p>If there's a vacancy, we would be happy to contact you!</p>
                      <JobApplicationForm />
                    </CardContent>
                  </Card>
                </ScrollAnimation>
              </Grid>
            )}
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default Careers;

