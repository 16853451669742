import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import macbook from "assets/images/macbook.png";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const StyledImage = styled('img')(({ theme }) => ({
  maxWidth: "20em",
  maxHeight: "29em",
  transition: "transform 0.3s ease",
  borderRadius: "7px",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const Feature = ({ Feature, Project }) => {
  const [imgSrc, setImgSrc] = useState(macbook);
  const storage = getStorage();

  useEffect(() => {
    const getImage = async () => {
      try {
        const imageRef = ref(storage, `PastProjects/${Project.id}/features/${Feature.name}`);
        const image = await getDownloadURL(imageRef);
        setImgSrc(image);
      } catch (error) {
        console.log("Error fetching image: ", error);
      }
    };

    getImage();
  }, [Project.id, Feature.name]);

  return (
    <Box component="section" py={6} my={6}>
      <Container>
        <Grid container className='projectCard'>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }} className="feature-image">
            <Box position="relative" className="image-container">
              <StyledImage
                src={imgSrc}
                alt={Feature.name || "Feature Image"}
                className="hover-image"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <Typography variant="h4">{Feature.name}</Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              {Feature.description}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Feature;

