import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Box, Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const StyledProjectCard = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3),
  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.3s ease, background-color 0.3s ease',
  '&:hover': {
    transform: 'translateY(10px)',
    cursor: 'pointer',
  },
}));

const StyledImage = styled('img')({
  width: '100%',
  borderRadius: '7px',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
});

const Project = ({ Project }) => {
  const [imgSrc, setImgSrc] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const storage = getStorage();
  const navigate = useNavigate();

  useEffect(() => {
    const getImage = async () => {
      try {
        const imageRef = ref(storage, `PastProjects/${Project.id}/CoverImage`);
        const image = await getDownloadURL(imageRef);
        setImgSrc(image);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching image: ", error);
        setError(true);
        setLoading(false);
      }
    };

    getImage();
  }, [Project.id]);

  const handleClick = () => {
    navigate("/PastProjects/Details", { state: { project: Project } });
  };

  return (
    <Container>
      <StyledProjectCard container onClick={handleClick}>
        <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
          <Box position="relative" overflow="hidden" borderRadius="7px">
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                <CircularProgress />
              </Box>
            ) : error ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                <ErrorOutlineIcon color="error" fontSize="large" />
              </Box>
            ) : (
              <StyledImage src={imgSrc} alt={Project.title} />
            )}
          </Box>
        </Grid>

        <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 3, md: 0 } }}>
          <Typography variant="h4" gutterBottom>{Project.title}</Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {Project.description}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button variant="outlined" color="primary" fullWidth onClick={handleClick}>
                Learn more
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </StyledProjectCard>
    </Container>
  );
};

export default Project;

