import React from 'react';
import { EventAvailable, Settings, CreditCard, Rocket, CheckCircle } from '@mui/icons-material';
import ScrollAnimation from '../../../ScrollAnimation/ScrollAnimation';
import './DesignBlocks.scss';

const data = [
  {
    icon: <EventAvailable fontSize="large" />,
    title: "Book a Consultation Session",
    description: "It's free and always will be.",
    items: [
      "Explain your needs and business cycle to us. Take your time, be detailed.",
      "We formulate a custom tailored solution, designed specifically to meet your unique requirements.",
    ],
  },
  {
    icon: <Settings fontSize="large" />,
    title: "Iron Out the Details",
    description: "Confirm the solution's requirements, timeline, and budget.",
    items: [
      "Agree on a set of acceptance criteria for the solution.",
      "Agree on a timeline that may include milestones depending on the solution's size.",
      "Agree on a budget for the solution. (Don't worry, we'll be flexible.)",
    ],
  },
  {
    icon: <CreditCard fontSize="large" />,
    title: "Pay a Deposit",
    description: "Not the entire amount, just the first payment.",
    items: [
      "Invest an agreed upon percentage of the solution's budget as a deposit.",
      "As soon as the deposit is received, we'll start working on the solution. You're in safe hands now!",
      "All payments are made secure with PayPal.",
    ],
  },
  {
    icon: <Rocket fontSize="large" />,
    title: "Project Launch",
    description: "The moment we've all been waiting for.",
    items: [
      "After the development phase is over, we start the deployment phase.",
      "We upload all the separate parts of your integrated solution to the cloud.",
      "We help you in finding, acquiring, and setting up a domain name if needed.",
    ],
  },
  {
    icon: <CheckCircle fontSize="large" />,
    title: "Finalization",
    description: "Take your time, test it out first.",
    items: [
      "We give you time to try the finished product and test it out for yourself.",
      "You provide feedback about any changes or revisions, and we will handle it.",
      "Once you are 100% satisfied, you pay the remainder of the agreed-upon budget.",
    ],
  },
];

const TimelineItem = ({ item, index }) => (
  <ScrollAnimation animation={index % 2 === 0 ? "slide-in-left" : "slide-in-right"}>
    <div className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
      <div className="timeline-point">
        {item.icon}
      </div>
      <div className="timeline-content">
        <h3>{item.title}</h3>
        <p>{item.description}</p>
        <ul>
          {item.items.map((listItem, i) => (
            <li key={i}>{listItem}</li>
          ))}
        </ul>
      </div>
    </div>
  </ScrollAnimation>
);

function DesignBlocks() {
  return (
    <section className="design-blocks">
      <div className="container">
        <ScrollAnimation>
          <h2 className="section-title">Our Development Process</h2>
        </ScrollAnimation>
        <div className="timeline">
          {data.map((item, index) => (
            <TimelineItem 
              key={index} 
              item={item} 
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default DesignBlocks;

