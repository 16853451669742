import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Presentation from "layouts/pages/presentation";
import routes from "routes";

import LandingPageHome from "./Views/LandingPage/Home";
import ContactUs from "./Views/LandingPage/ContactUs";
import ConsultationProgram from "./Views/ConsultationProgram";
import Careers from "./Views/Careers";
import Jobs from "./Views/Jobs";
import PastProjects from "./Views/PastProjects";
import ProjectDetails from "./Views/PastProjects/ProjectDetails";
import NavBar from './devComponents/Navbars/PublicNavbar';
import Footer from "./Views/Footer";
import { useScrollToTop } from "./useScrollToTop";

import './Views/LandingPage/Home/main.scss';

function App() {
  useScrollToTop();

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <div className="app">
      <NavBar />
      <main className="main-content">
        <Routes>
          {getRoutes(routes)}
          <Route path="/presentation" element={<Presentation />} />
          <Route path="/" element={<LandingPageHome />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/ConsultationProgram" element={<ConsultationProgram />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/PastProjects" element={<PastProjects />} />
          <Route path="/PastProjects/Details" element={<ProjectDetails />} />
          <Route path="/Jobs" element={<Jobs />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;

