import React, { useState, useEffect } from 'react';
import { Box } from "@mui/material";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";
import Project from './Project';

const ProjectsList = () => {
  const [projects, setProjects] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const collectionRef = collection(db, 'pastProjects');
    const unsubscribe = onSnapshot(collectionRef, snapshot => {
      const tempProjects = snapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(project => project.id !== "Metadata");
      setProjects(tempProjects);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Box className="ProjectsList">
      {projects.map(project => (
        <Project key={project.id} Project={project} />
      ))}
    </Box>
  );
}

export default ProjectsList;

