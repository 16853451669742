import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { TextField, Button, Grid } from '@mui/material';
import { EventAvailable, Email, Phone, Subject, Message, VideoCall, AccessTime, DateRange } from '@mui/icons-material';
import Swal from "sweetalert2";
import { getFirestore, addDoc, doc, collection, serverTimestamp, setDoc, increment } from "firebase/firestore";

import './ConsultationForm.scss';




function ConsultationForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [meetingPlatform, setMeetingPlatform] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [meetingDate, setMeetingDate] = useState("");

  const db = getFirestore();

  const handleSubmit = async () => {
    if (name === "" || email === "" || secondaryContact === "" || subject === "" || message === "" || meetingPlatform === "" || meetingTime === "" || meetingDate === "") {
      Swal.fire({
        title: "Error",
        text: "Please fill all the fields",
        icon: "error",
        confirmButtonText: "OK"
      });
      return;
    }

    if (!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
      Swal.fire({
        title: "Error",
        text: "Please enter a valid email",
        icon: "error",
        confirmButtonText: "OK"
      });
      return;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const collectionRef = collection(db, "consultationProgram");
    const res = await addDoc(collectionRef, {
      name,
      email,
      secondaryContact,
      subject,
      message,
      meetingPlatform,
      meetingTime,
      meetingDate,
      createdAt: serverTimestamp(),
      timezone
    });

    const docRef = doc(db, "consultationProgram", "consultationProgramData");
    await setDoc(docRef, { count: increment(1) }, { merge: true });

    document.getElementById("consultationForm").reset();
    Swal.fire({
      title: "Success",
      text: `Your request has been submitted. Your Request ID is ${res.id}. We will contact you at the earliest.`,
      icon: "success",
      confirmButtonText: "OK"
    });
  };

  return (
    <div className="consultation-form">
      <h2>Book Your Consultation</h2>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            InputProps={{
              startAdornment: <EventAvailable />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            InputProps={{
              startAdornment: <Email />,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Secondary Contact"
            value={secondaryContact}
            onChange={(e) => setSecondaryContact(e.target.value)}
            InputProps={{
              startAdornment: <Phone />,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
            InputProps={{
              startAdornment: <Subject />,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            InputProps={{
              startAdornment: <Message />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Meeting Platform"
            value={meetingPlatform}
            onChange={(e) => setMeetingPlatform(e.target.value)}
            required
            InputProps={{
              startAdornment: <VideoCall />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Meeting Time"
            type="time"
            value={meetingTime}
            onChange={(e) => setMeetingTime(e.target.value)}
            required
            InputProps={{
              startAdornment: <AccessTime />,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Meeting Date"
            type="date"
            value={meetingDate}
            onChange={(e) => setMeetingDate(e.target.value)}
            required
            InputProps={{
              startAdornment: <DateRange />,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Button onClick={handleSubmit} variant="contained" color="primary" className="submit-button">
        Send Request
      </Button>
    </div>
  );
}

export default ConsultationForm;

