import React from 'react';
import { Container, Grid } from '@mui/material';
import './ConsultationProgram.scss';
import ScrollAnimation from 'Views/ScrollAnimation/ScrollAnimation';
import ConsultationForm from './ConsultationForm';

function ConsultationProgram() {
  return (
    <div className="consultation-program">
      <header className="consultation-program__hero">
        <div className="container">
          <ScrollAnimation animation="fade-in">
            <h1>The devHouse Consultation Program</h1>
            <p>Congratulations for making it this far!</p>
          </ScrollAnimation>
        </div>
      </header>
      <main className="consultation-program__main">
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <ScrollAnimation animation="slide-in-left">
                <div className="consultation-program__info">
                  <h2>Free Consultation Sessions</h2>
                  <p>The DevHouse Consultation Program offers free, friendly sessions to answer your questions about our products, services, or tech in general. Schedule at your convenience with no commitment required!</p>
                </div>
              </ScrollAnimation>
            </Grid>
            <Grid item xs={12} md={6}>
              <ScrollAnimation animation="slide-in-right">
                <ConsultationForm />
              </ScrollAnimation>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default ConsultationProgram;

