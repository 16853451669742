import React from 'react';
import { Chat, Security, Update } from '@mui/icons-material';
import ScrollAnimation from '../../../ScrollAnimation/ScrollAnimation';
import './TheThreePrincipals.scss';

const PrincipalCard = ({ icon, title, description, animation }) => (
  <ScrollAnimation animation={animation}>
    <div className="principal-card">
      <div className="icon">{icon}</div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </ScrollAnimation>
);

function TheThreePrincipals() {
  return (
    <section className="three-principals">
      <div className="container">
        <ScrollAnimation>
          <h2 className="section-title">Our Principles</h2>
        </ScrollAnimation>
        <div className="principals-grid">
          <PrincipalCard
            icon={<Chat fontSize="large" />}
            title="Communication is Key"
            description="We deeply understand our clients' needs and goals to develop the best possible solutions."
            animation="slide-in-left"
          />
          <PrincipalCard
            icon={<Security fontSize="large" />}
            title="100% Hassle Free"
            description="From development to deployment and maintenance, we handle all technical aspects so you can focus on your business."
            animation="fade-in"
          />
          <PrincipalCard
            icon={<Update fontSize="large" />}
            title="Long-term Partnership"
            description="Our commitment extends beyond development, ensuring your project runs smoothly with ongoing support and maintenance."
            animation="slide-in-right"
          />
        </div>
      </div>
    </section>
  );
}

export default TheThreePrincipals;

