
import { Container, Grid, TextField, Button } from "@mui/material";
import { getFirestore, addDoc, doc, collection, serverTimestamp, onSnapshot } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
// import useState and useEffect to handle the form state and submission
import { useState, useEffect } from "react";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import './JobApplication.scss'
import Swal from "sweetalert2";
import { CloudUpload, DateRange, Description, Email, Person, Phone, Work } from "@mui/icons-material";

function JobApplicationForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [currentJob, setCurrentJob] = useState("");
  const [availableToStart, setAvailableToStart] = useState("");

  const db = getFirestore();
  const storage = getStorage();

  const handleSubmit = async () => {
    console.log(name , email,currentJob,availableToStart,secondaryContact,subject,message)
    if (name === "" || email === "" || secondaryContact === "" || subject === "" || message === ""
      || currentJob === "" || availableToStart === "") {
      Swal.fire({
        title: "Error",
        text: "Please fill all the fields",
        icon: "error",
        confirmButtonText: "OK"
      });
      return;
    }
    // check email with regex

    if(!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
      Swal.fire({
        title: "Error",
        text: "Please enter a valid email",
        icon: "error",
        confirmButtonText: "OK"
      });
      return;
    }


    // get the user's timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // set the document in the database
    addDoc(collection(db, "jobApplications"), {
      name,
      email,
      secondaryContact,
      subject,
      message,
      currentJob,
      availableToStart,
      createdAt: serverTimestamp(),
      timezone
    }).then(async (res) => { 
      // reset the form
      
      // upload the resume and other files in input "resumeFiles" 
      const resumeFiles = document.getElementById("resumeFiles").files;
      if (resumeFiles.length > 0) {
        console.log("resumeFiles", resumeFiles)
        // upload the all the files
        for (let i = 0; i < resumeFiles.length; i++) {
          const file = resumeFiles[i];
          const fileName = file.name;
          const fileType = file.type;
          const filePath = "jobApplications/" + res.id + "/" + fileName;
          const fileRef = ref(storage, filePath);
          uploadBytes(fileRef, file).then((res) => {
            console.log("Uploaded file: " + fileName, res);
          })
          .catch((err) => {
            console.log("Error uploading file: " + fileName, err)
          });
        }
      }
      document.getElementById("jobApplicationForm").reset();
      Swal.fire({
        title: "Success",
        text: "Your request has been submitted. Your Request ID is " + res.id + ". We will get contact you at the earliest.",
        icon: "success",
        confirmButtonText: "OK"
      });

    }
    ).catch(error => {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK"
      });
    });
  }


  return (
    <Container>
      <div id="jobApplicationForm" className="job-application-form">
        <h2>Apply Now</h2>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              InputProps={{
                startAdornment: <Person />,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              InputProps={{
                startAdornment: <Email />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Secondary Contact"
              value={secondaryContact}
              onChange={(e) => setSecondaryContact(e.target.value)}
              InputProps={{
                startAdornment: <Phone />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Interested Department(s)"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              InputProps={{
                startAdornment: <Work />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Why do you want to join our team?"
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              InputProps={{
                startAdornment: <Description />,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Current Job Title"
              value={currentJob}
              onChange={(e) => setCurrentJob(e.target.value)}
              required
              InputProps={{
                startAdornment: <Work />,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Available to Start"
              type="date"
              value={availableToStart}
              onChange={(e) => setAvailableToStart(e.target.value)}
              required
              InputProps={{
                startAdornment: <DateRange />,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              id="resumeFiles"
              multiple
              type="file"
              style={{ display: 'none' }}
            />
            <label htmlFor="resumeFiles">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUpload />}
                fullWidth
                className="upload-file"
              >
                Upload Resume and Other Files
              </Button>
            </label>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className="submit-button"
              onClick={handleSubmit}
            >
              Send Application
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default JobApplicationForm;

