import React from 'react';
import { Lightbulb, TrendingUp, AccessTime, Rocket } from '@mui/icons-material';
import './Information.scss';

const InfoCard = ({ icon, title, description }) => (
  <div className="info-card">
    <div className="icon">{icon}</div>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

function Information() {
  return (
    <section className="information">
      <div className="container">
        <h2 className="section-title">Our Services</h2>
        <div className="info-grid">
          <InfoCard
            icon={<Lightbulb fontSize="large" />}
            title="Explore The Possibilities"
            description="Have a friendly chat with our project managers and learn how we can help your business grow."
          />
          <InfoCard
            icon={<TrendingUp fontSize="large" />}
            title="For Established Businesses"
            description="We optimize and automate your business processes to drive growth and efficiency."
          />
          <InfoCard
            icon={<AccessTime fontSize="large" />}
            title="Save Time & Money"
            description="We handle everything from planning to maintenance, allowing you to focus on your core business."
          />
          <InfoCard
            icon={<Rocket fontSize="large" />}
            title="For New Businesses"
            description="We turn your ideas into reality quickly and reliably, leveraging our expertise in what works best."
          />
        </div>
      </div>
    </section>
  );
}

export default Information;
