import React from 'react';
import { useNavigate } from "react-router-dom";
import TheThreePrincipals from "./sections/TheThreePrincipals";
import Information from "./sections/Information";
import DesignBlocks from "./sections/DesignBlocks";
import ScrollAnimation from '../../ScrollAnimation/ScrollAnimation';
import './Presentation.scss';
import DevIcon4 from "../../../../src/assets/images/5097235.gif"

function Presentation() {
  let navigate = useNavigate();

  return (
    <div className="presentation">
      <header className="hero">
        <div className="container">
          <ScrollAnimation animation="fade-in">
            <div className="hero-content">
              <h1>Welcome to DevHouse</h1>
              <p>The one-stop shop for all your software development and tech needs.</p>
              <button className="btn" onClick={() => navigate("/ConsultationProgram")}>
                BOOK FREE CONSULTATION SESSION
              </button>
            </div>
          </ScrollAnimation>
            <div className="hero-image">
              <img src={DevIcon4} alt="Development Icon" />
            </div>
        </div>
      </header>
      <main>
        <ScrollAnimation>
          <TheThreePrincipals />
        </ScrollAnimation>
        <ScrollAnimation>
          <Information />
        </ScrollAnimation>
        <ScrollAnimation>
          <DesignBlocks />
        </ScrollAnimation>
      </main>
    </div>
  );
}

export default Presentation;

