import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/housedevhouselogowhite.png';
import './Footer.scss';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__columns">
                    {/* Column 1: Company Name */}
                    <div className="footer__column footer__company">
                        <img src={Logo} alt="DevHouse Logo" className="footer__logo" />
                        <p>We are a full-service software house dedicated to creating impactful digital experiences.</p>
                    </div>

                    {/* Column 2: Explore */}
                    <div className="footer__column footer__explore">
                        <h3>Explore</h3>
                        <NavLink to="/Careers" className="footer__link">Careers</NavLink>
                        <NavLink to="/contactus" className="footer__link">Contact Us</NavLink>
                    </div>

                    {/* Column 3: Services */}
                    <div className="footer__column footer__services">
                        <h3>Services</h3>
                        <ul>
                            <li><span>Web Development</span></li>
                            <li><span>Mobile App Development</span></li>
                            <li><span>Software Solutions</span></li>
                            <li><span>Automation System</span></li>
                        </ul>
                    </div>

                    {/* Column 4: Contact */}
                    <div className="footer__column footer__contact">
                        <h3>Contact</h3>
                        <ul>
                            <li>
                                <a href="https://www.linkedin.com/company/thedevhouse1" target="_blank" rel="noreferrer">
                                    <i className="fab fa-linkedin"></i> LinkedIn
                                </a>
                            </li>
                            <li>
                                <i className="fas fa-phone-alt"></i> (+1) 4158003336
                            </li>
                            <li>
                                <i className="fas fa-phone-alt"></i> (+20) 1001612869
                            </li>
                            <li>
                                <a href="mailto:info@thedevhouse.io">
                                    <i className="fas fa-envelope"></i> info@thedevhouse.io
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

