import React from 'react';
import { Card, Container, Grid, Typography, Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import bgImage from "assets/images/LandingPage/PastProjects.jpg";
import ProjectsList from './ProjectsList';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${bgImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "35rem",
  width: "100%",
  display: "grid",
  placeItems: "center",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2, 3),
  marginTop: "-64px",
  marginBottom: theme.spacing(4),
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: "saturate(200%) blur(30px)",
  boxShadow: theme.shadows[10],
}));

const Index = () => {
  return (
    <>
      <Box bgcolor="background.default">
        <StyledBox />
        <StyledCard>
          <Container>
            <Grid container mb={3} item xs={12} lg={7} justifyContent="center" mx="auto">
              <Typography variant="h2" color="text.primary" align="center" gutterBottom>
                A Sample of our Past Projects
              </Typography>
              <Typography variant="h5" color="text.secondary" align="center" paragraph>
                We've developed a lot of projects over the years, these are just a few!
              </Typography>
            </Grid>
          </Container>
          <ProjectsList />
        </StyledCard>
      </Box>
    </>
  );
}

export default Index;

