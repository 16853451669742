import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from "../../../assets/images/housedevhouselogowhite.png";
import './Navbar.scss';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleNavigation = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''} ${isMenuOpen ? 'menu-open' : ''}`}>
      <div className="navbar__background"></div>
      <div className="container navbar__content">
        <div className="navbar__logo" onClick={() => handleNavigation('/')}>
          <img src={Logo} alt="DevHouse Logo" />
        </div>
        <button className="navbar__toggle" onClick={toggleMenu} aria-label="Toggle menu">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className="navbar__menu">
          <ul className="navbar__links">
            <li><button className={`navbar__link ${isActive('/') ? 'active' : ''}`} onClick={() => handleNavigation('/')}>Home</button></li>
            <li><button className={`navbar__link ${isActive('/PastProjects') ? 'active' : ''}`} onClick={() => handleNavigation('/PastProjects')}>Past Projects</button></li>
            <li><button className={`navbar__link ${isActive('/Careers') ? 'active' : ''}`} onClick={() => handleNavigation('/Careers')}>Careers</button></li>
            <li><button className={`navbar__link ${isActive('/ContactUs') ? 'active' : ''}`} onClick={() => handleNavigation('/ContactUs')}>Contact Us</button></li>
          </ul>
          <button className="navbar__cta btn" onClick={() => handleNavigation('/ConsultationProgram')}>Book Consultation</button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;


