import React, { useState, useEffect } from 'react';
import { Card, Container, Grid, Typography, Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Feature from './Feature';

const StyledBox = styled(Box)(({ theme }) => ({
  minHeight: "25rem",
  width: "100%",
  backgroundSize: "contain",
  backgroundPosition: "center",
  display: "grid",
  placeItems: "center",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2, 3),
  marginTop: "-64px",
  marginBottom: theme.spacing(4),
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: "saturate(200%) blur(30px)",
  boxShadow: theme.shadows[10],
}));

const Index = () => {
  const location = useLocation();
  const [imgSrc, setImgSrc] = useState("");
  const [project, setProject] = useState(location.state.project);
  const storage = getStorage();

  useEffect(() => {
    const getImage = async () => {
      try {
        const imageRef = ref(storage, `PastProjects/${project.id}/CoverImage`);
        const image = await getDownloadURL(imageRef);
        setImgSrc(image);
      } catch (error) {
        console.log("Error fetching image: ", error);
      }
    };

    getImage();
  }, [project.id]);

  return (
    <>
      <Box bgcolor="background.default">
        <StyledBox
          sx={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${imgSrc})`,
          }}
        />
        <StyledCard>
          <Container>
            <Grid container mb={3} item xs={12} lg={5} md={5} justifyContent="center" mx="auto">
              <Typography variant="h2" color="text.primary" align="center" gutterBottom>
                {project.title}
              </Typography>
              <Typography variant="h5" color="text.secondary" align="center" paragraph>
                {project.description}
              </Typography>
            </Grid>
          </Container>
          {project.features.map((feature, index) => (
            <Feature key={index} Project={project} Feature={feature} />
          ))}
        </StyledCard>
      </Box>
    </>
  );
}

export default Index;

