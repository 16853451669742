import React from 'react';
import './ContactUs.scss';
import ContactInfo from './sections/ContactInfo';
import ContactForm from './sections/ContactForm';
import ScrollAnimation from 'Views/ScrollAnimation/ScrollAnimation';

const ContactUs = () => {
  return (
    <div className="contact-us">
      <header className="contact-us__hero">
        <div className="container">
          <ScrollAnimation animation="fade-in">
            <h1>Contact Us</h1>
            <p>Get in touch with us for any inquiries or support</p>
          </ScrollAnimation>
        </div>
      </header>
      <main className="contact-us__main">
        <div className="container">
          <div className="contact-us__content">
            <ScrollAnimation animation="slide-in-left">
              <ContactInfo />
            </ScrollAnimation>
            <ScrollAnimation animation="slide-in-right">
              <ContactForm />
            </ScrollAnimation>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContactUs;

