import React from 'react';
import { Phone, Mail, LocationOn, Facebook, LinkedIn, WhatsApp } from '@mui/icons-material';

const ContactInfo = () => {
  return (
    <div className="contact-info">
      <h2>Contact Information</h2>
      <p>Reach out to us directly or book a consultation session.</p>
      <ul className="contact-info__list">
        <li>
          <Phone fontSize="small" />
          <span>(+20) 1001612869</span>
        </li>
        <li>
          <Mail fontSize="small" />
          <span>info@thedevhouse.io</span>
        </li>
        <li>
          <LocationOn fontSize="small" />
          <span>16 Saad Hussein Zaki St. Heliopolis, Cairo, Egypt</span>
        </li>
      </ul>
      <div className="contact-info__social">
        <a href="https://www.facebook.com/devHouse.hbtec" target="_blank" rel="noopener noreferrer">
          <Facebook />
        </a>
        <a href="https://eg.linkedin.com/" target="_blank" rel="noopener noreferrer">
          <LinkedIn />
        </a>
        <a href="https://wa.me/+201001612869" target="_blank" rel="noopener noreferrer">
          <WhatsApp />
        </a>
      </div>
    </div>
  );
};

export default ContactInfo;
