import React from 'react';
import { useNavigate } from 'react-router-dom';

const ContactForm = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <div className="contact-form">
      <h2>Say Hi!</h2>
      <p>We'd love to talk with you.</p>
      <form onSubmit={handleSubmit}>
        <p>
          Book a free consultation session to ask us about anything you like. We will be happy to answer any questions you have and we respond FAST!
        </p>
        <button 
          type="button" 
          className="btn" 
          onClick={() => navigate("/ConsultationProgram")}
        >
          Book Consultation Session
        </button>
      </form>
    </div>
  );
};

export default ContactForm;

