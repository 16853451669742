import React from "react";
import { Card, CardContent, Container, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import "./JobApplication.scss";
import JobApplicationForm from "./JobApplicationForm";
import ScrollAnimation from "Views/ScrollAnimation/ScrollAnimation";

function JobApplication() {
  const location = useLocation();
  const { jobTitle, department, Description, jobText, PostDate } = location.state || {};

  return (
    <div className="job-application">
      <header className="job-application__hero">
        <div className="container">
          <ScrollAnimation animation="fade-in">
            <h1>Apply As {jobTitle || "Job"}</h1>
            <p>Congratulations for making it this far!</p>
          </ScrollAnimation>
        </div>
      </header>
      <main className="job-application__main">
        <Container>
          <Card className="job-application__card">
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <ScrollAnimation animation="slide-in-left">
                    <div className="job-details">
                      <h2>Job Details</h2>
                      {department && <p><strong>Department:</strong> {department}</p>}
                      <p><strong>Posted From:</strong> {PostDate}</p>
                      <h3>Description:</h3>
                      <p>{Description}</p>
                      <h3>Requirements:</h3>
                      <ul>
                        {jobText && jobText.map((text, index) => (
                          <li key={index}>{text.text}</li>
                        ))}
                      </ul>
                    </div>
                  </ScrollAnimation>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ScrollAnimation animation="slide-in-right">
                    <JobApplicationForm />
                  </ScrollAnimation>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </main>
    </div>
  );
}

export default JobApplication;

